import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { now, toISO } from 'lib/utils/date';
import _get from 'lodash/get';
import queryString from 'query-string';
import routes from 'lib/utils/routes';

import { getLaborTicketAction } from 'lib/selectors/getLaborTicketAction';
import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import { getMachine } from 'lib/selectors/getMachine';
import { getCurrentLaborTicket } from 'lib/selectors/getLaborTickets';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import {
  actionSetLaborTicketActionLoading,
  actionSetPendingActivityTypeRef,
} from 'lib/actions';
import { throwErrorToast } from 'lib/utils/toast';
import { getLatestOperatorId } from 'lib/selectors/getLatestOperatorId';
import { getOperators } from 'lib/selectors/getOperators';
import { getLatestActivityMode } from 'lib/selectors/getLatestActivityMode';
import { getWorkOrderRedirectPath } from 'lib/selectors/getWorkOrderRedirectPath';

import useEditLaborTicket from './hooks/useEditLaborTicket';
import useCreateLaborTicket from './hooks/useCreateLaborTicket';
import useOperatorLogout from './hooks/useOperatorLogout';
import useStopRun from './hooks/useStopRun';
import useChangeActivities from './hooks/useChangeActivities';
import useCloseOrderOperaiton from './hooks/useCloseOrderOperation';

const useSubmitLaborTicket = ({ form } = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = queryString.parse(history.location.search);

  const editLaborTicket = useEditLaborTicket();
  const createLaborTicket = useCreateLaborTicket();
  const requestOperatorLogout = useOperatorLogout();
  const workOrderRedirectPath = useSelector(getWorkOrderRedirectPath);
  const requestStopRun = useStopRun();
  const requestChangeActivities = useChangeActivities();
  const closeOrderOperation = useCloseOrderOperaiton();

  const machine = useSelector(getMachine);
  const latestActivityMode = useSelector(getLatestActivityMode);
  const latestOperatorId = useSelector(getLatestOperatorId);
  const operators = useSelector(getOperators);

  const laborTicketAction = useSelector(getLaborTicketAction);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const workOrderId = _get(currentWorkOrderOp, 'workOrderId');

  const closeOrder = form?.workOrderComplete.value;
  const isStopCurrentRun = closeOrder || queryParams.startAndStop;
  const currentLaborTicket = useSelector(getCurrentLaborTicket);

  const submitLaborTicket = async (opts = {}) => {
    if (
      laborTicketAction === LABOR_TICKET_ACTIONS.PAUSE ||
      !currentWorkOrderOp
    ) {
      return;
    }
    const nowISO = toISO(now().valueOf());

    try {
      if (currentLaborTicket && form) {
        const rejectedParts = form.rejectedParts.value || 0;
        const goodParts = form.totalParts.value - rejectedParts;
        const reason = form.rejectReason.value;

        editLaborTicket(
          {
            clockOut: nowISO,
            state: 'CLOSED',
            goodParts,
            badParts: rejectedParts,
            comment: form.comment.value || null,
          },
          currentLaborTicket.laborTicketRef,
          reason?.reasonRef
        );
      }

      if (closeOrder) {
        await closeOrderOperation(
          { closedDate: nowISO },
          currentWorkOrderOp.workOrderOperationRef
        );
      }

      const laborTicketOperatorId = opts.loggingInOperator || latestOperatorId;
      const personId = operators.find((op) => {
        return op.id === laborTicketOperatorId;
      })?.erpId;

      if (
        !isStopCurrentRun &&
        laborTicketAction !== LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT &&
        laborTicketAction !== LABOR_TICKET_ACTIONS.STOP_RUN &&
        personId
      ) {
        const { split = '', lot = '', sub = '' } =
          currentWorkOrderOp.workOrder || {};

        createLaborTicket({
          clockIn: nowISO,
          clockOut: null,
          lot,
          sequenceNumber: currentWorkOrderOp.sequenceNumber,
          split,
          sub,
          state: 'OPEN',
          resourceId: machine.erpResourceId,
          personId,
          workOrderId,
          type: latestActivityMode,
          goodParts: 0,
          badParts: 0,
        });
      }

      if (workOrderRedirectPath) {
        history.push(workOrderRedirectPath);
      }
    } catch (e) {
      dispatch(actionSetLaborTicketActionLoading(false));
      throwErrorToast(`Could not submit labor ticket: ${e}`);
    }
  };

  const requestLaborTicketAction = async ({ action, opts = {} } = {}) => {
    switch (action) {
      case LABOR_TICKET_ACTIONS.STOP_RUN:
        await requestStopRun();
        return submitLaborTicket();

      case LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT: {
        await requestOperatorLogout();
        if (isStopCurrentRun) {
          await requestStopRun();
        }
        await submitLaborTicket();
        return history.push({
          pathname: routes.machineIdHomePath(machine.id),
        });
      }

      case LABOR_TICKET_ACTIONS.OPERATOR_LOGIN: {
        const startAt = _get(opts, 'startAt');
        const operatorId = _get(opts, 'loggingInOperator');
        await submitLaborTicket({ startAt, loggingInOperator: operatorId });
        if (isStopCurrentRun) {
          await requestStopRun();
          return history.push(routes.machineIdSelectJobPath(machine.id));
        }
        return history.push({
          pathname: routes.machineIdHomePath(machine.id),
        });
      }

      case LABOR_TICKET_ACTIONS.CHANGE_ACTIVITIES: {
        if (isStopCurrentRun) {
          await requestStopRun();
          submitLaborTicket();
          return history.push(routes.machineIdSelectJobPath(machine.id));
        }
        await requestChangeActivities();
        return submitLaborTicket();
      }

      default:
        return null;
    }
  };

  return async ({ action, opts = {} } = {}) => {
    dispatch(actionSetLaborTicketActionLoading(true));

    if (_get(opts, 'pendingActivityTypeRef')) {
      await dispatch(
        actionSetPendingActivityTypeRef(_get(opts, 'pendingActivityTypeRef'))
      );
    }

    return requestLaborTicketAction({
      action: action || laborTicketAction,
      opts,
    });
  };
};

export default useSubmitLaborTicket;
