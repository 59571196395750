import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from '@m12s/component-library';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  getCurrentWorkOrderOp,
  getCurrentWorkOrderOpTimeToComplete,
} from 'lib/selectors/getCurrentWorkOrderOp';

import { abbreviateQuantity, getOperationDurationText } from './helpers';

const WorkOrderBodyContainer = styled.div`
  display: grid;
  padding: 0.75rem;
  gap: 1rem;
  text-wrap: nowrap;
  grid-template-columns: 1fr 2fr;
  align-items: center;
`;

const QuantityMade = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${palette.Grey700};
  line-height: 2rem;
`;

const QuantityRequired = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${palette.Grey700};
`;

const StatRow = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const StatHeading = styled.div`
  font-size: 0.625rem;
  line-height: 0.875rem;
  font-weight: 400;
  color: ${palette.Grey700};
`;

const StatValue = styled(StatHeading)`
  font-weight: 600;
`;

const Stat = ({ label, value, ...props }) => {
  return (
    <div {...props}>
      <StatHeading>{label}</StatHeading>
      <StatValue>{value}</StatValue>
    </div>
  );
};

Stat.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const WorkOrderBody = ({ workOrderOp, active }) => {
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const currentWorkOrderOpTimeToComplete = useSelector(
    getCurrentWorkOrderOpTimeToComplete
  );

  const {
    sub,
    split,
    lot,
    scheduledStartDate,
    startQuantity,
    finishQuantity,
    totalGoodParts,
    startAt,
    workOrder,
    startEstimatedDateOfCompletion,
  } = workOrderOp;

  const operationDuration = getOperationDurationText({
    operation: workOrderOp,
    currentOperation: currentWorkOrderOp,
    currentWorkOrderOpTimeToComplete:
      active && currentWorkOrderOpTimeToComplete,
  });

  const durationText =
    moment
      .duration(operationDuration)
      .format('h[h] m[m] s[s]', { largest: 2 }) || '0s';

  return (
    <WorkOrderBodyContainer>
      <div>
        <QuantityMade>
          {abbreviateQuantity(active ? totalGoodParts : startQuantity) || '-'}
        </QuantityMade>
        <QuantityRequired>{`/ ${abbreviateQuantity(finishQuantity) ||
          'N/A'}`}</QuantityRequired>
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: '5.5rem 5.5rem' }}>
        {active ? (
          <Stat
            label="Start Date"
            value={moment(startAt).format('L')}
            style={{ marginBottom: '.25rem' }}
          />
        ) : (
          <Stat
            label={
              startEstimatedDateOfCompletion ? 'Est. Start' : 'Planned Start'
            }
            value={
              startEstimatedDateOfCompletion
                ? moment(startEstimatedDateOfCompletion).format('L')
                : scheduledStartDate
                ? moment(scheduledStartDate).format('L')
                : 'N/A'
            }
            style={{ marginBottom: '.25rem' }}
          />
        )}

        <Stat
          label="PO Due Date"
          value={
            workOrder?.dueDate ? moment(workOrder.dueDate).format('L') : 'N/A'
          }
        />
        <Stat label="Est. Duration" value={durationText || 'N/A'} />
        <StatRow>
          <Stat label="Lot" value={lot} />
          <Stat label="Split" value={split} />
          <Stat label="Sub" value={sub} />
        </StatRow>
      </div>
    </WorkOrderBodyContainer>
  );
};

WorkOrderBody.propTypes = {
  workOrderOp: PropTypes.object,
  active: PropTypes.bool,
};

export default WorkOrderBody;
